export var DOTS_PAGINATION_THEMES = {
  light: {
    arrowLeftSpacingClassName: 'mr-8',
    arrowRightSpacingClassName: 'ml-8',
    dot: {
      "default": {
        sizeClassname: 'w-12 h-12 mx-8',
        colorClassname: 'bg-gray-dark-24'
      },
      active: {
        sizeClassname: 'w-12 h-12 mx-8',
        colorClassname: 'bg-primary-main'
      }
    }
  },
  dark: {
    arrowLeftSpacingClassName: 'mr-8',
    arrowRightSpacingClassName: 'ml-8',
    dot: {
      "default": {
        sizeClassname: 'w-12 h-12 mx-8',
        colorClassname: 'bg-gray-dark-24'
      },
      active: {
        sizeClassname: 'w-12 h-12 mx-8',
        colorClassname: 'bg-secondary-main'
      }
    }
  },
  transparent: {
    arrowLeftSpacingClassName: 'mr-4',
    arrowRightSpacingClassName: 'ml-4',
    dot: {
      "default": {
        sizeClassname: 'w-8 h-8 mx-4',
        colorClassname: 'border border-solid border-gray-light'
      },
      active: {
        sizeClassname: 'w-8 h-8 mx-4',
        colorClassname: 'bg-secondary-main'
      }
    }
  }
};