export var TRANSLATIONS = {
  en: {
    PAGE: 'Page',
    PAGE_SM: 'Page',
    OF: 'of'
  },
  es: {
    PAGE: 'Página',
    PAGE_SM: 'Pág',
    OF: 'de'
  },
  pt: {
    PAGE: 'Página',
    PAGE_SM: 'Pág',
    OF: 'de'
  }
};